<template>
  <div id="container" v-if="fetch_data">

    <section id="mypage">
      <div class="wrap">

        <div class="cts_box">
          <h2 class="sub_ttl"><span>塾生クラスの変更</span></h2>
          <h3 class="min_ttl">ご希望の変更内容をご確認ください。</h3>
          <div class="class_bf_af flex">
            <div class="inBox flex bf">
              <div class="list flex">
                <span>現在のクラス</span>
                <p>{{ current_user.rank_label }}</p>
              </div>
              <div class="list flex">
                <span>お支払い方法</span>
                <p>{{ current_user.payment_monthly ? '月額払い' : '年額払い' }}</p>
              </div>
              <div class="list flex" v-if="false">
                <span>次回の更新月</span>
                <p>{{ moment(current_user.next_payment_date, 'YYYY年MM月') }}</p>
              </div>
              <div class="list flex" v-if="false">
                <span>次の支払いまであと</span>
                <p>{{ diffMonth(new Date(), new Date(current_user.next_payment_date)) }}ヶ月</p>
              </div>
            </div>
            <div class="arrow"></div>
            <div class="inBox flex af">
              <h4>変更を希望するクラス</h4>
              <div class="new_class">{{ newRankLabel() }}</div>
              <p class="red bold" v-if="new_rank === 'regular'">「会社四季報ONLINE」の優待プランをお選びいただけます<br>（年額払いのみ）</p>
              <p class="red bold" v-if="new_rank === 'regular_plus'">「会社四季報ONLINE」のプレミアムプランが含まれます。</p>
            </div>
          </div>
        </div>

        <!-- regular -->
        <div id="register" class="cts_box">

          <!-- regular_plus -->
          <div class="reg_set first" v-if="new_rank === 'regular_plus'">
            <div class="class_list_box">
              <div class="tax_ttl flex flex_betweem head">
                <h4>お支払い方法</h4>
                <p class="c01"></p>
                <p class="c02">次回のお支払い額</p>
                <p class="tax_caution bold red">※表示はすべて税込価格です</p>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="plus_payment01" v-model="year_month" value="year" @change="yearMonthChanged()">
                <label class="flex" for="plus_payment01">
                  <div class="inner flex">
                    <div class="class">本科プラス 年額払い</div>
                    <div class="price ttl_in"><span
                        class="title sp"><br></span>
                    </div>
                    <div class="price ttl_in"><span class="title sp">次回の<br>お支払い額</span>¥264,000/年</div>
                    <div class="info">月あたり¥22,000</div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="plus_payment02" v-model="year_month" value="month" @change="yearMonthChanged()">
                <label class="flex" for="plus_payment02">
                  <div class="inner flex">
                    <div class="class">本科プラス 月額払い</div>
                    <div class="price ttl_in"><span
                        class="title sp"><br></span>
                    </div>
                    <div class="price ttl_in"><span class="title sp">次回の<br>お支払い額</span>¥27,500/月</div>
                    <div class="info">&nbsp;</div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
            </div>
          </div>

          <div class="reg_set first" v-if="new_rank === 'regular'">
            <div class="class_list_box">
              <div class="tax_ttl flex flex_betweem head">
                <h4>お支払い方法</h4>
                <p class="c01"></p>
                <p class="c02">次回のお支払い額</p>
                <p class="tax_caution bold red">※表示はすべて税込価格です</p>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="payment01" v-model="year_month" value="year" @change="yearMonthChanged()">
                <label class="flex" for="payment01">
                  <div class="inner flex">
                    <div class="class">本科 年額払い</div>
                    <div class="price ttl_in"><span
                        class="title sp"><br></span>
                    </div>
                    <div class="price ttl_in"><span class="title sp">次回の<br>お支払い額</span>¥162,000/年</div>
                    <div class="info">月あたり¥13,500</div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="payment02" v-model="year_month" value="month" @change="yearMonthChanged()">
                <label class="flex" for="payment02">
                  <div class="inner flex">
                    <div class="class">本科 月額払い</div>
                    <div class="price ttl_in"><span
                        class="title sp"><br></span>
                    </div>
                    <div class="price ttl_in"><span class="title sp">次回の<br>お支払い額</span>¥16,500/月</div>
                    <div class="info">&nbsp;</div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
            </div>

            <div class="class_list_box shikihou" v-bind:class="{disabled: year_month === 'month'}">
              <!--本科の月額選択時は disabled 追加 変更先が予科、本科プラスの時は非表示-->
              <div class="tax_ttl flex flex_betweem head">
                <h4 class="full">東洋経済新報社の「会社四季報ONLINE」の優待プランを追加できます（年額払いのみ）</h4>
                <p class="c00">&nbsp;</p>
                <p class="c01"></p>
                <p class="c02">次回のお支払い額</p>
                <p class="tax_caution bold red">※表示はすべて税込価格です</p>
              </div>
              <div class="class_list flex check_radio">
                <input type="radio" id="shikihou_none" v-model="shikiho" value="none" @change="shikihoChanged()">
                <label class="flex" for="shikihou_none">
                  <div class="inner flex">
                    <div class="class shikihou_option min">会社四季報ONLINE の優待プランを選択しない</div>
                    <div class="price pc">&nbsp;</div>
                    <div class="info pc">&nbsp;</div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="shikihou_premium" v-model="shikiho" value="premium" @change="shikihoChanged()">
                <label class="flex" for="shikihou_premium">
                  <div class="inner flex">
                    <div class="class">四季報ONLINE プレミアムプランを追加する</div>
                    <div class="price ttl_in"><span class="title sp"><br></span>
                    </div>
                    <div class="price ttl_in"><span class="title sp">次回の<br>お支払い額</span>+¥50,000/年</div>
                    <div class="info">通常より¥8,000お得</div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="shikihou_basic" v-model="shikiho" value="basic" @change="shikihoChanged()">
                <label class="flex" for="shikihou_basic">
                  <div class="inner flex">
                    <div class="class">四季報ONLINE ベーシックプランを追加する</div>
                    <div class="price ttl_in"><span class="title sp"><br></span>
                    </div>
                    <div class="price ttl_in"><span class="title sp">次回の<br>お支払い額</span>+¥12,000/年</div>
                    <div class="info">通常より¥500お得</div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
            </div>
          </div>

          <!-- preparatory -->
          <div class="reg_set first" v-if="new_rank === 'preparatory'">
            <div class="class_list_box">
              <div class="tax_ttl flex flex_betweem head">
                <h4>お支払い方法</h4>
                <p class="c01"></p>
                <p class="c02">次回のお支払い額</p>
                <p class="tax_caution bold red">※表示はすべて税込価格です</p>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="preparatory_payment02" v-model="year_month" value="month"
                       @change="yearMonthChanged()">
                <label class="flex" for="preparatory_payment02">
                  <div class="inner flex">
                    <div class="class">予科 月額払い</div>
                    <div class="price ttl_in"><span
                        class="title sp"><br></span>
                    </div>
                    <div class="price ttl_in"><span class="title sp">次回の<br>お支払い額</span>¥4,950/月</div>
                    <div class="info">&nbsp;</div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="rank === current_user.rank" style="margin-bottom: 20px;">
          <p>※ 現在契約中のプランです</p>
        </div>

        <div v-bind:class="{ 'point-disabled': !fetch_data[rank].price }" v-if="false">
          <div class="cts_box claim workshop" v-if="true">
            <h3 class="min_ttl">ポイントの利用</h3>
            <p class="lead_txt">ポイントを利用する場合は、ポイント数を入力の上「ポイントを利用する」ボタンを押してください。</p>
            <table class=""><!-- ポイント利用できない場合はclass="point_none" を追加 -->
              <tbody>
              <tr class="head">
              </tr>
              <tr>
                <td class="date">現在のポイント数</td>
                <td class="price">{{ delimited(total_points) }}<span>ポイント</span></td>
                <td class="point flex">
                  <h3 class="sp">ポイント利用額（1ポイント＝1円）</h3>
                  <p>ご利用ポイントを入力</p>
                  <div class="point_input">
                    <input type="text" v-model="use_point"
                           @input="use_point = replaceFullToNumber(use_point)"
                           v-bind:disabled="total_points === 0"
                           maxlength="6" class="point">
                    <p class="caution">最大 {{ delimited(Math.min(total_points, fetch_data[rank].price)) }}
                      ポイントまでご利用いただけます</p>
                    <p>1ポイント＝1円として利用できます</p>
                  </div>
                  <p class="bold">ポイント</p>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="btn">
                  <button type="button" class="submit light skeleton"
                          v-bind:disabled="total_points === 0"
                          @click="usePointClicked">ポイントを利用する
                  </button>
                </td>
                <!--<td colspan="3" class="btn"><input type="submit" class="submit light skeleton" value="現在ポイントの利用はできません" disabled></td> ポイント利用できない場合-->
              </tr>
              </tbody>
            </table>
          </div>

          <div class="cts_box">
            <h3 class="min_ttl">お会計内容</h3>
            <div class="register_box">
              <dl class="flex">
                <dt>{{ fetch_data[rank].title }} への変更</dt>
                <dd class="price">¥{{ delimited(fetch_data[rank].price) }}</dd>
              </dl>
              <dl class="flex" v-if="false">
                <dt>クーポンのご利用</dt>
                <dd class="item"></dd>
                <dd class="price">¥-6,500</dd>
              </dl>
              <dl class="flex" v-if="point">
                <dt>ポイントのご利用</dt>
                <dd class="item"></dd>
                <dd class="price">-{{ delimited(point) }}</dd>
              </dl>
              <dl class="flex total">
                <dt></dt>
                <dd class="item">お会計合計：</dd>
                <dd class="price">¥{{ delimited(total_price) }}</dd>
              </dl>
            </div>
          </div>
        </div>

        <div class="btn_wrap mt20">
          <p class="alnC sp_alnL mb10">内容がよろしければこちらのボタンを押してください。</p>
          <a href="javascript:void(0);" class="submit light arrow large sp_h_auto"
             v-bind:class="{disabled: !changeEnabled()}" @click="changeRank()">変更内容の確認</a>
        </div>

        <div class="btn_wrap mt40">
          <p class="alnC sp_alnL mb10">変更を中止する場合はこちらのボタンを押してください。</p>
          <router-link :to="{name: 'UsersCustomerNoJoinRankChange'}" class="submit light skeleton arrow back large">
            前のページにもどる
          </router-link>
        </div>

      </div>
    </section>

    <div id="b_listBox">
      <ul id="b_list">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>
          <router-link :to="{name: 'UsersCustomerAccount'}">マイページ</router-link>
        </li>
        <li>塾生クラスの変更</li>
      </ul>
    </div>

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  data() {
    return {
      fetch_data: null,
      new_rank: this.$route.params.new_rank,
      rank: null,
      year_month: this.$route.params.new_rank === 'preparatory' ? 'month' : 'year',
      shikiho: 'none',

      total_points: 0,
      use_point: null,
      point: null,
      total_price: null,
    }
  },
  methods: {
    newRankLabel() {
      return {
        preparatory: '予科',
        regular: '本科',
        regular_plus: '本科プラス'
      }[this.new_rank]
    },

    yearMonthChanged() {
      if (this.year_month === 'month') {
        this.shikiho = 'none'
      }
      this.selectedNewRank()
    },

    usePointClicked() {
      if (isNaN(this.use_point)) return

      if (this.use_point > this.fetch_data[this.rank].price) {
        this.use_point = this.fetch_data[this.rank].price
      }
      this.point = this.use_point
      this.total_price = this.fetch_data[this.rank].price - this.use_point
    },

    changeEnabled() {
      return this.rank !== null && this.current_user.rank !== this.rank && this.fetch_data[this.rank].level !== 'error'
    },

    shikihoChanged() {
      this.selectedNewRank()
    },

    selectedNewRank() {
      let new_rank = null
      if (this.new_rank === 'regular_plus') {
        if (this.year_month === 'year') {
          new_rank = 'rank_regular_plus_year'
        } else {
          new_rank = 'rank_regular_plus_month'
        }
      } else if (this.new_rank === 'regular') {
        if (this.year_month === 'year') {
          if (this.shikiho === 'premium') {
            new_rank = 'rank_regular_shikiho_premium_year'
          } else if (this.shikiho === 'basic') {
            new_rank = 'rank_regular_shikiho_basic_year'
          } else {
            new_rank = 'rank_regular_year'
          }
        } else {
          new_rank = 'rank_regular_month'
        }
      } else if (this.new_rank === 'preparatory') {
        new_rank = 'rank_preparatory_month'
      }
      this.rank = new_rank

      this.use_point = this.point = null
      this.total_price = this.fetch_data[this.rank].price - this.use_point
    },

    changeRank() {
      // form token
      this.setFormToken('RankChange')

      // ランク変更確認へ
      this.$store.state.change_rank_info = this.fetch_data[this.rank]
      this.$store.state.change_rank_info.rank = this.rank
      this.$store.state.change_rank_info.year_month = this.year_month
      this.$store.state.change_rank_info.use_point = this.point
      this.$store.state.change_rank_info.total_price = this.total_price

      this.$router.push({name: 'UsersCustomerNoJoinRankChangeConfirm'})
    }
  },

  mounted() {
    if (!this.current_user.no_join_rank_change_enabled) {
      this.$router.push({name: 'UsersCustomerAccount'})
      return
    }
    // 旧塾生が本科を選んだら旧塾生専用のページへ
    if (this.current_user.sns_rank === 'rank_regular_default' && this.$route.params.new_rank === 'regular') {
      this.$router.push({name: 'UsersCustomerNoJoinRankChangeDefault'})
    }

    // ランク変更先の価格リストを取得
    this.startAjax()
    this.axios
        .post(`${this.env.api_base_url}users/new_rank_info.json`, {
          new_rank: this.new_rank
        })
        .then((response) => {
          // console.log(response.data)
          this.fetch_data = response.data
          this.total_points = response.data.total_points
          this.shikihoChanged()
          if (this.$store.state.change_rank_info) {
            this.year_month = this.$store.state.change_rank_info.year_month
            this.rank = this.$store.state.change_rank_info.rank
            this.use_point = this.point = this.$store.state.change_rank_info.use_point
            this.total_price = this.fetch_data[this.rank].price - this.use_point
          }
        })
        .catch((response) => {
          this.errorAjax(response)
        })
        .finally(() => {
          this.finishAjax()
        })
  }
}
</script>

<style>
.point-disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
